import { Container, Product, Title } from "../../components";
import { Swiper, SwiperSlide } from "swiper/react";
import { fileUrl, useFETCH } from "../../Tools/APIs";
function Customers({ api, title }) {
  const { data, isLoading } = useFETCH(
    api ||
      `properties?local=${window.localStorage.getItem("language")}&trending=1`
  );
  return (
    <div>
      <Container>
        <Title title={title || "Recommended"} />
        <Swiper
          slidesPerView={1.1}
          spaceBetween={5}
          breakpoints={{
            1200: {
              slidesPerView: 4.2,
            },
            950: {
              slidesPerView: 3.2,
            },
            0: {
              slidesPerView: 2,
            },
          }}
        >
          {isLoading ? (
            <>
              <SwiperSlide className="">
                <div className="w-full h-[400px]  rounded-2xl p-5 bg-white border">
                  <div className="bg-slate-300 animate-pulse w-full h-[220px] rounded-2xl"></div>
                  <div className="bg-slate-300 animate-pulse w-2/3 h-6 my-2 "></div>
                  <div className="bg-slate-300 animate-pulse w-2/5 h-5 my-2 "></div>
                  <div className="flex gap-5">
                    <div className="bg-slate-300 animate-pulse w-8 h-8 my-2 rounded-full"></div>
                    <div className="bg-slate-300 animate-pulse w-8 h-8 my-2 rounded-full"></div>
                    <div className="bg-slate-300 animate-pulse w-8 h-8 my-2 rounded-full"></div>
                  </div>
                  <div className="bg-slate-300 animate-pulse w-2/5 h-5 my-2 "></div>
                </div>
              </SwiperSlide>
              <SwiperSlide className="">
                <div className="w-full h-[400px]  rounded-2xl p-5 bg-white border">
                  <div className="bg-slate-300 animate-pulse w-full h-[220px] rounded-2xl"></div>
                  <div className="bg-slate-300 animate-pulse w-2/3 h-6 my-2 "></div>
                  <div className="bg-slate-300 animate-pulse w-2/5 h-5 my-2 "></div>
                  <div className="flex gap-5">
                    <div className="bg-slate-300 animate-pulse w-8 h-8 my-2 rounded-full"></div>
                    <div className="bg-slate-300 animate-pulse w-8 h-8 my-2 rounded-full"></div>
                    <div className="bg-slate-300 animate-pulse w-8 h-8 my-2 rounded-full"></div>
                  </div>
                  <div className="bg-slate-300 animate-pulse w-2/5 h-5 my-2 "></div>
                </div>
              </SwiperSlide>
              <SwiperSlide className="">
                <div className="w-full h-[400px]  rounded-2xl p-5 bg-white border">
                  <div className="bg-slate-300 animate-pulse w-full h-[220px] rounded-2xl"></div>
                  <div className="bg-slate-300 animate-pulse w-2/3 h-6 my-2 "></div>
                  <div className="bg-slate-300 animate-pulse w-2/5 h-5 my-2 "></div>
                  <div className="flex gap-5">
                    <div className="bg-slate-300 animate-pulse w-8 h-8 my-2 rounded-full"></div>
                    <div className="bg-slate-300 animate-pulse w-8 h-8 my-2 rounded-full"></div>
                    <div className="bg-slate-300 animate-pulse w-8 h-8 my-2 rounded-full"></div>
                  </div>
                  <div className="bg-slate-300 animate-pulse w-2/5 h-5 my-2 "></div>
                </div>
              </SwiperSlide>
              <SwiperSlide className="">
                <div className="w-full h-[400px]  rounded-2xl p-5 bg-white border">
                  <div className="bg-slate-300 animate-pulse w-full h-[220px] rounded-2xl"></div>
                  <div className="bg-slate-300 animate-pulse w-2/3 h-6 my-2 "></div>
                  <div className="bg-slate-300 animate-pulse w-2/5 h-5 my-2 "></div>
                  <div className="flex gap-5">
                    <div className="bg-slate-300 animate-pulse w-8 h-8 my-2 rounded-full"></div>
                    <div className="bg-slate-300 animate-pulse w-8 h-8 my-2 rounded-full"></div>
                    <div className="bg-slate-300 animate-pulse w-8 h-8 my-2 rounded-full"></div>
                  </div>
                  <div className="bg-slate-300 animate-pulse w-2/5 h-5 my-2 "></div>
                </div>
              </SwiperSlide>
            </>
          ) : (
            data?.data.data.data.map((e) => (
              <SwiperSlide className="">
                <Product
                  id={e.id}
                  NewProperty=""
                  details="asd"
                  title={e.name}
                  address={e.address}
                  room={e.rooms}
                  houseArea={e.house_area}
                  image={fileUrl + e.main_image?.file}
                  bathroom={e.bathroom}
                  whatsapp={e.whatsapp}
                  phone={e.phone_number}
                  price={e.price}
                  map={e.url_location}
                  isFavorite={e.is_favorite}
                />
              </SwiperSlide>
            ))
          )}
        </Swiper>
      </Container>
    </div>
  );
}
export default Customers;
