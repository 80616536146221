import { useContextTranslate } from "../Translate/ContextProvider";
import { Col, Row } from "../../Tools/Grid-system";
import { useFilter, useFETCH } from "../../Tools/APIs";
import Button from "../Button/Button";

const Filters = ({ total, show, setShow, types }) => {
  const { content } = useContextTranslate();
  const { handleParamsClick, searchParams, handleParamsDelete } = useFilter();
  const { data } = useFETCH(
    "property-type?local=" +
      localStorage.getItem("language") +
      "&type=" +
      searchParams.get("type")
  );
  return (
    <>
      {show && (
        <div>
          <div className="fixed h-full max-sm:w-[90%] w-1/2 bg-white bottom-0 z-[999] left-0 overflow-y-scroll chat overflow-x-hidden">
            <div className="sticky top-0 bg-white py-2 px-3 flex justify-between items-center ">
              <div className="text-xl font-semibold pb-3">
                {content.SearchOptions}
              </div>
              <div
                onClick={() => setShow(false)}
                className="w-fit border px-4 py-2 rounded-2xl ml-auto text-xl font-semibold hover:bg-slate-300 hover:text-red-500 cursor-default"
              >
                X
              </div>
            </div>
            <div>
              <div className="bg-slate-200 my-3  ">
                <h1 className="text-xl font-semibold pb-3">
                  {content.PropertyType}
                </h1>
                <Row>
                  {(data?.data.data.length > 0 ? data?.data.data : types)?.map(
                    (e) => (
                      <Rooms
                        nameFilter="property_type"
                        number={`${e.id}`}
                        name={e.name}
                      />
                    )
                  )}
                </Row>
              </div>
              <div className="bg-slate-200 my-3">
                <h1 className="text-xl font-semibold pb-3">{content.Rooms}</h1>
                <Row>
                  <Rooms nameFilter="rooms" number="1" />
                  <Rooms nameFilter="rooms" number="2" />
                  <Rooms nameFilter="rooms" number="3" />
                  <Rooms nameFilter="rooms" number="4" />
                  <Rooms nameFilter="rooms" number="5" plus={true} />
                </Row>
              </div>
              <div className="bg-slate-200 my-3 py-2 px-2">
                <h1 className="text-xl font-semibold pb-3">
                  {content.Bathroom}
                </h1>
                <Row>
                  <Rooms nameFilter="bathroom" number="1" />
                  <Rooms nameFilter="bathroom" number="2" />
                  <Rooms nameFilter="bathroom" number="3" />
                  <Rooms nameFilter="bathroom" number="4" />
                  <Rooms nameFilter="bathroom" number="5" plus={true} />
                </Row>
              </div>
              <div className="bg-slate-200 my-3 py-2 px-2">
                <h1 className="text-xl font-semibold pb-3">
                  {content.YearsOfInstallments}
                </h1>
                <Row>
                  <Rooms nameFilter="installment_years" number="1" />
                  <Rooms nameFilter="installment_years" number="2" />
                  <Rooms nameFilter="installment_years" number="3" />
                  <Rooms nameFilter="installment_years" number="4" />
                  <Rooms nameFilter="installment_years" number="5" />
                  <Rooms
                    nameFilter="installment_years"
                    number="6"
                    plus={true}
                  />
                </Row>
              </div>
              <div className="bg-slate-200 my-3 py-2 px-2">
                <h1 className="text-xl font-semibold pb-3">
                  {content.DeliveryDate}
                </h1>
                <Row>
                  <Rooms
                    nameFilter="delivery_date"
                    name={content.Now}
                    number="now"
                  />
                  <Rooms
                    nameFilter="delivery_date"
                    name="+2024"
                    number="date"
                  />
                </Row>
              </div>
              <div className="bg-slate-200 my-3 py-2 px-2">
                <h1 className="text-xl font-semibold pb-3">
                  {content.SquareMeters}
                </h1>
                <Row>
                  <Col xs={6}>
                    <input
                      type="number"
                      className="py-2 px-4 rounded-xl text-xs font-semibold border-2 border-black "
                      value={searchParams.get("min_house_area")}
                      placeholder={content.from}
                      onChange={(e) => {
                        if (e.target.value.length > 0) {
                          handleParamsClick("min_house_area", e.target.value);
                        } else {
                          handleParamsDelete("min_house_area");
                        }
                      }}
                    />
                  </Col>
                  <Col xs={6}>
                    <input
                      type="number"
                      className="py-2 px-4 rounded-xl text-xs font-semibold border-2 border-black "
                      value={searchParams.get("max_house_area")}
                      placeholder={content.to}
                      onChange={(e) => {
                        if (e.target.value.length > 0) {
                          handleParamsClick("max_house_area", e.target.value);
                        } else {
                          handleParamsDelete("max_house_area");
                        }
                      }}
                    />
                  </Col>
                </Row>
              </div>
              <div className="bg-slate-200 my-3 py-2 px-2">
                <h1 className="text-xl font-semibold pb-3">
                  {content.TypesOfFinishing}
                </h1>
                <Row>
                  <Rooms
                    sm="6"
                    md="4"
                    nameFilter="types_of_finishing"
                    number="1"
                    name={content.WithoutFinishing}
                  />
                  <Rooms
                    sm="6"
                    md="4"
                    number="2"
                    nameFilter="types_of_finishing"
                    name={content.SemiFinished}
                  />
                  <Rooms
                    sm="6"
                    md="4"
                    number="3"
                    nameFilter="types_of_finishing"
                    name={content.FullyFinished}
                  />
                  <Rooms
                    sm="6"
                    md="4"
                    number="4"
                    nameFilter="types_of_finishing"
                    name={content.Furnished}
                  />
                </Row>
              </div>
              <Row
                justify="center"
                className="sticky bottom-0 bg-white py-2 px-3"
              >
                <Col xs={6}>
                  <Button
                    onClick={() => {
                      handleParamsDelete("property_type");
                      handleParamsDelete("rooms");
                      handleParamsDelete("bathroom");
                      handleParamsDelete("installment_years");
                      handleParamsDelete("delivery_date");
                      handleParamsDelete("min_house_area");
                      handleParamsDelete("max_house_area");
                      handleParamsDelete("types_of_finishing");
                    }}
                    title={content.CancelAll}
                    className="bg-white border border-black !text-Main hover:bg-slate-100"
                  />
                </Col>
                <Col xs={6}>
                  <Button
                    onClick={() => setShow(false)}
                    title={content.ShowingResults + " " + total}
                    className="!bg-Main"
                  />
                </Col>
              </Row>
            </div>
          </div>
          <div
            onClick={() => setShow(false)}
            className="fixed h-full  max-sm:w-[10%] w-1/2 bg-black/30 bottom-0 z-[99999999] right-0 "
          />
        </div>
      )}
    </>
  );
};

export default Filters;

const Rooms = ({ number, nameFilter, plus, name, sm, md }) => {
  const { handleParamsClick, searchParams, handleParamsDelete } = useFilter();
  return (
    <Col sm={sm || 4} md={md || 3}>
      <div
        onClick={() => {
          if (
            searchParams.has(nameFilter) &&
            searchParams.get(nameFilter) === number
          ) {
            handleParamsDelete(nameFilter);
          } else {
            handleParamsClick(nameFilter, number);
          }
        }}
        className={`${
          searchParams.get(nameFilter) === number
            ? "border-Main text-Main"
            : "border-black"
        } border-2  rounded-2xl  flex items-center justify-center mx-3 cursor-pointer font-bold min-w-10 h-10 text-xs`}
      >
        {plus ? "+" + number : name ? name : number}
      </div>
    </Col>
  );
};
