import JoditEditor from "jodit-react";
import { useState } from "react";
import { useParams } from "react-router-dom";
import Select from "react-select";
import makeAnimated from "react-select/animated";
import { fileUrl, useFETCH, usePOST } from "../../Tools/APIs";
import AddImage from "../../Tools/AddImage/AddImage";
import { Col, Row } from "../../Tools/Grid-system";
import Loading from "../../Tools/Loading";
import { Back, ButtonRed, Container, Input, Selected } from "../../components";
const animatedComponents = makeAnimated();
const AddProperties = () => {
  const { id } = useParams();
  const {
    handleChangeInput,
    handleSubmit,
    setFormData,
    setViewImages,
    handleChangeArrayImages,
    setImages,
    setCheckArray,
    images,
    viewImages,
    formData,
    loading,
    error,
  } = usePOST({ trending: 0, is_available: 0 });
  const { data: dataCompound } = useFETCH(`compounds?local=none`);
  const { data: dataPropertyType } = useFETCH(`property-type?local=none`);
  const { data: dataCoins } = useFETCH(`coins?is_active=1&local=none`);
  const { data: dataAttached } = useFETCH(`attacheds?local=none`);
  const handleSubmitMain = (e) => {
    e.preventDefault();
    handleSubmit(id === "add" ? "properties" : `properties/${id}`);
  };
  const compoundOption = dataCompound?.data.data.data.map((e) => {
    return { value: e.id, label: e.name };
  });
  const propertyTypeOption = dataPropertyType?.data.data.map((e) => {
    return { value: e.id, label: e.name };
  });
  console.log(dataPropertyType?.data.data);
  const CoinsOption = dataCoins?.data.data.data.map((e) => {
    return { value: e.id, label: e.code };
  });
  const attachedOption = dataAttached?.data.data.map((e) => {
    return { value: e.id, label: e.name };
  });
  const [selectedAttached, setSelectedAttached] = useState([]);
  const [selectedCoins, setSelectedCoins] = useState();
  const [selectedCompound, setSelectedCompound] = useState();
  const [selectedPropertyType, setSelectedPropertyType] = useState();
  return (
    <div style={{ direction: "rtl" }}>
      <Container className="px-1">
        <Row
          justify="center"
          className="items-center border-2 border-Main p-2 rounded-xl"
        >
          <Col md={6}>
            <Input
              name="ar_name"
              value={formData?.ar_name}
              onChange={handleChangeInput}
              title="الاسم بالعربي"
            />
          </Col>
          <Col md={6}>
            <Input
              name="en_name"
              value={formData?.en_name}
              onChange={handleChangeInput}
              title="الاسم بالإنكليزي"
              className="text-end"
            />
          </Col>
          <Col md={6}>
            <Input
              name="ar_address"
              value={formData?.ar_address}
              onChange={handleChangeInput}
              title="الموقع بالعربي"
            />
          </Col>
          <Col md={6}>
            <Input
              name="en_address"
              value={formData?.en_address}
              onChange={handleChangeInput}
              title="الموقع بالإنكليزي"
              className="text-end"
            />
          </Col>
          <Col>
            <h1 className="mb-2">الوصف بالعربي</h1>
            <JoditEditor
              value={formData?.ar_description}
              onChange={(e) => setFormData({ ...formData, ar_description: e })}
            />
          </Col>
          <Col>
            <h1 className="mb-2">الوصف بالإنكليزي</h1>
            <JoditEditor
              value={formData?.en_description}
              onChange={(e) => setFormData({ ...formData, en_description: e })}
            />
          </Col>
          <Col md={6}>
            <h1 className="mb-2">خطط الدفع بالعربي</h1>
            <JoditEditor
              value={formData?.ar_payment_plans}
              onChange={(e) =>
                setFormData({ ...formData, ar_payment_plans: e })
              }
            />
          </Col>
          <Col md={6}>
            <h1 className="mb-2">خطط الدفع بالإنكليزي</h1>
            <JoditEditor
              value={formData?.en_payment_plans}
              onChange={(e) =>
                setFormData({ ...formData, en_payment_plans: e })
              }
            />
          </Col>
          <Col md={6}>
            <Input
              type="number"
              name="whatsapp"
              value={formData?.whatsapp}
              onChange={handleChangeInput}
              title="رقم الواتس "
            />
          </Col>
          <Col md={6}>
            <Input
              type="number"
              name="phone_number"
              value={formData?.phone_number}
              onChange={handleChangeInput}
              title="رقم الجوال "
            />
          </Col>
          <Col md={4}>
            <Input
              type="number"
              name="rooms"
              value={formData?.rooms}
              onChange={handleChangeInput}
              title="عدد الغرف"
            />
          </Col>
          <Col md={4}>
            <Input
              type="number"
              name="bathroom"
              value={formData?.bathroom}
              onChange={handleChangeInput}
              title="عدد الحمامات"
            />
          </Col>
          <Col md={4}>
            <Input
              type="number"
              name="house_area"
              value={formData?.house_area}
              onChange={handleChangeInput}
              title="المساحة بالمتر المربع"
            />
          </Col>
          <Col md={6}>
            <Input
              type="number"
              name="price"
              value={formData?.price}
              onChange={handleChangeInput}
              title="السعر"
            />
          </Col>
          <Col md={6}>
            <Input
              name="url_location"
              value={formData?.url_location}
              onChange={handleChangeInput}
              title="رابط الموقع"
            />
          </Col>
          <Col md={6}>
            <Input
              name="initial_payment"
              value={formData?.initial_payment}
              onChange={handleChangeInput}
              title="الدفعة الأولية"
            />
          </Col>
          <Col md={6}>
            <Input
              name="monthly_installment"
              value={formData?.monthly_installment}
              onChange={handleChangeInput}
              title="القسط الشهري"
            />
          </Col>
          <Col md={6}>
            <Input
              name="installment_years"
              value={formData?.installment_years}
              onChange={handleChangeInput}
              title="سنوات التقسيط"
            />
          </Col>
          <Col md={6}>
            <Input
              type="date"
              name="delivery_date"
              value={formData?.delivery_date}
              onChange={handleChangeInput}
              title="تاريخ التسليم"
            />
          </Col>
          <Col md={6} className="my-3 px-1">
            <h1 className={`text-end mb-2`}>المرفقات</h1>
            <Select
              closeMenuOnSelect={false}
              components={animatedComponents}
              isMulti
              name="attached[]"
              value={selectedAttached}
              onChange={(selectedOptions) => {
                const selectedValues = selectedOptions?.map((option) => [
                  option.value,
                ]);
                setSelectedAttached(selectedOptions);
                setCheckArray({ "attached[]": selectedValues });
              }}
              options={attachedOption}
            />
          </Col>
          <Col md={6} className="my-3 px-1">
            <h1 className={`mb-2`}>العملة</h1>
            <Select
              options={CoinsOption}
              name="coin_id"
              value={selectedCoins}
              onChange={(selectedCoin) => {
                setSelectedCoins(selectedCoin);
                setFormData({ ...formData, coin_id: selectedCoin.value });
              }}
            />
          </Col>
          <Col md={6} className="my-3 px-1">
            <h1 className={`mb-2`}>نوع العقار</h1>
            <Select
              options={propertyTypeOption}
              name="property_type"
              value={selectedPropertyType}
              onChange={(selectedProperty) => {
                setSelectedPropertyType(selectedProperty);
                setFormData({
                  ...formData,
                  property_type: selectedProperty.value,
                });
              }}
            />
          </Col>
          <Col md={6} className="my-3 px-1">
            <h1 className={`mb-2`}>المجمع السكني</h1>
            <Select
              options={compoundOption}
              name="compound_id"
              value={selectedCompound}
              onChange={(selectedCom) => {
                setSelectedCompound(selectedCom);
                setFormData({ ...formData, compound_id: selectedCom.value });
              }}
            />
          </Col>
          <Col md={7} className="my-3 px-1">
            <Selected
              text="أنواع التشطيب"
              title="اختر أنواع التشطيب"
              name="types_of_finishing"
              value={formData?.types_of_finishing}
              onChange={handleChangeInput}
            >
              <option value="1">11</option>
              <option value="2">22</option>
              <option value="3">33</option>
              <option value="4">44</option>
            </Selected>
          </Col>
          <Col md={6} className="relative top-3 px-1">
            <h1 className="mb-2">أضف صورة الموقع</h1>
            <AddImage
              id="images"
              name="image_location"
              newImage={viewImages?.image_location?.[0]}
              onChange={handleChangeArrayImages}
              title="إضف صورة الموقع "
              clickDeleteImage={() => {
                setViewImages({
                  ...viewImages,
                  image_location: "",
                });
                setImages({
                  ...images,
                  image_location: "",
                });
              }}
            />
          </Col>
          <Col md={6} className="relative top-3 px-1">
            <h1 className="mb-2">أضف صورة مخطط الطابق</h1>
            <AddImage
              id="images2"
              name="image_floor_plan"
              newImage={viewImages?.image_floor_plan?.[0]}
              onChange={handleChangeArrayImages}
              title="إضف صورة مخطط الطابق"
              clickDeleteImage={() => {
                setViewImages({
                  ...viewImages,
                  image_floor_plan: "",
                });
                setImages({
                  ...images,
                  image_floor_plan: "",
                });
              }}
            />
          </Col>
          <Row>
            <Col className="mx-auto relative top-3 px-1">
              <h1 className="mb-2">أضف مجموعة صور</h1>
              <AddImage
                accept="video image"
                title="أضف مجموعة صور"
                type="files"
                name="files[]"
                onChange={handleChangeArrayImages}
              />
              <Row className="py-4">
                {viewImages["files[]"] &&
                  viewImages["files[]"]?.map((e, i) => (
                    <Col md={3} xs={4} sm={6} lg={24}>
                      <div className="relative">
                        <span
                          onClick={() => {
                            let total = [...viewImages["files[]"]];
                            let total2 = [...images["files[]"]];
                            total.splice(i, 1);
                            total2.splice(i, 1);
                            setViewImages({
                              ...viewImages["files[]"],
                              "files[]": total,
                            });
                            setImages({
                              ...images["files[]"],
                              "files[]": total2,
                            });
                          }}
                          className="bg-red-700 w-6 h-6 flex justify-center items-center font-bold text-white cursor-pointer z-10 -top-2 rounded-full absolute"
                        >
                          X
                        </span>
                        <img src={e} alt="" className="w-full rounded-2xl" />
                      </div>
                    </Col>
                  ))}
              </Row>
            </Col>
          </Row>
          <Col col={6}>
            <div className="flex items-center gap-3 my-4 text-lg font-semibold">
              <input
                type="checkbox"
                name="is_available"
                checked={formData.is_available}
                onChange={handleChangeInput}
                className="w-6 h-6"
              />
              <p>is available</p>
            </div>
          </Col>
          {loading ? <Loading /> : ""}
          <div className="text-red-500 font-semibold">{error}</div>
          <Col col={6}>
            <ButtonRed
              onClick={handleSubmitMain}
              name="Add"
              className="h-full px-4 py-3"
            />
          </Col>
        </Row>
        <Back />
      </Container>
    </div>
  );
};

export default AddProperties;
