import { useState } from "react";
import { BsListNested } from "react-icons/bs";
import { NavLink, useNavigate } from "react-router-dom";
import { useClose } from "../../Tools/APIs";
import { useContextTranslate } from "../Translate/ContextProvider";
const PopUpUser = () => {
  const { open, setOpen, mouse } = useClose(false);
  const { content, data } = useContextTranslate();
  const [sure, setSure] = useState(false);
  const navigate = useNavigate();
  const handleFormSubmit = () => {
    window.location.reload();
    localStorage.clear();
    navigate("/login");
  };
  return (
    <>
      {sure && (
        <>
          <div
            className="fixed top-0 left-0 w-full h-full bg-black bg-opacity-60 z-50"
            onClick={() => setSure(false)}
          ></div>
          <div
            className={`fixed top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 bg/black/60 z-50 flex justify-center items-center max-sm:w-full`}
          >
            <div className="bg-white text-black z-[60] rounded-3xl w-[500px] max-w-[500px] min-h-[200px]">
              <p className="font-semibold text-3xl text-center py-7">
                هل انت متأكد من تسجيل الخروج ؟
              </p>
              <div className="flex items-center justify-center gap-5 m-5">
                <button
                  onClick={handleFormSubmit}
                  className="mx-2 border px-10 border-Secondary text-Secondary bg-white font-semibold  p-3 rounded-xl ml-5"
                >
                  نعم
                </button>
                <button
                  onClick={() => setSure(false)}
                  className="mx-2 border px-10 border-Secondary text-Secondary bg-white font-semibold  p-3 rounded-xl ml-5"
                >
                  إلغاء
                </button>
              </div>
            </div>
          </div>
        </>
      )}
      <div
        className=" relative flex justify-center gap-5 max-smm:gap-0 w-[60px] cursor-pointer"
        ref={mouse}
      >
        <div className="hover:bg-slate-300 hover:rounded-full flex justify-center items-center p-1 w-9 h-9">
          <BsListNested
            className="w-[25px] h-[25px] max-smm:w-[22px] max-smm:h-[22px]"
            onClick={() => setOpen(!open)}
          />
          <div
            className={` ${
              open
                ? `bg-white absolute shadow-lg w-[200px] rounded-xl px-3 py-2 text-center text-lg top-10 ${
                    localStorage.getItem("language") === "en"
                      ? "right-0"
                      : "left-0"
                  } `
                : "hidden"
            } `}
          >
            <div className="text-center text-sm text-Main border-b pb-2">
              <div>{data?.data.data.profile.name}</div>
              <div>{data?.data.data.profile.email}</div>
            </div>
            {localStorage.getItem("token") && (
              <NavLink to="/favorite">
                <p
                  className="text-Main py-2 border-b"
                  onClick={() => setOpen(false)}
                >
                  {content.Favorite}
                </p>
              </NavLink>
            )}
            {localStorage.getItem("token") && data?.data.data.sell && (
              <NavLink to="/my-property">
                <p
                  className="text-Main py-2 border-b"
                  onClick={() => setOpen(false)}
                >
                  {content.myProperty}
                </p>
              </NavLink>
            )}
            <NavLink to="/terms-of-use">
              <p
                className="text-Main py-2 border-b"
                onClick={() => setOpen(false)}
              >
                {content.TermsOfUse}
              </p>
            </NavLink>
            <NavLink to="/how-it-works">
              <p
                className="text-Main py-2 border-b"
                onClick={() => setOpen(false)}
              >
                {content.HowItWorks}
              </p>
            </NavLink>
            {localStorage.getItem("token") ? (
              <p
                className="text-Main py-2 border-b"
                onClick={() => setSure(true)}
              >
                {content.logout}
              </p>
            ) : (
              <>
                <NavLink to="/sign-up">
                  <p
                    className="text-Main py-2 border-b"
                    onClick={() => setOpen(false)}
                  >
                    {content.SignUp}
                  </p>
                </NavLink>
                <NavLink to="/log-in">
                  <p className="text-Main py-2" onClick={() => setOpen(false)}>
                    {content.SignIn}
                  </p>
                </NavLink>
              </>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default PopUpUser;
