import { useContextTranslate } from "../../components/Translate/ContextProvider";
import Property from "../Property/Property";

function MyProperty() {
  const { content, data } = useContextTranslate();
  return (
    <div>
      <Property
        pram={`?local=${localStorage.getItem("language")}&user_id=${
          data?.data.data.profile?.id
        }`}
      />
    </div>
  );
}
export default MyProperty;
