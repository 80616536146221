function Selected({
  children,
  text,
  title,
  name,
  value,
  onChange,
  className,
}) {
  return (
    <div className="">
      {text && <h1 className={`text-start mb-2`}>{text}</h1>}
      <select
        name={name}
        value={value}
        onChange={onChange}
        className={`w-full border-2 border-Main py-3 rounded-xl text-lg outline-none text-center ${className}`}
      >
        <option value="" disabled selected hidden>
          {title}
        </option>
        {children}
      </select>
    </div>
  );
}
export default Selected;
