import React, { useState } from "react";
import { fileUrl, useFETCH } from "../../Tools/APIs";
import { Col, Container, Row } from "../../Tools/Grid-system";
import { Filters, Product, SecondSlider } from "../../components";
import { useLocation } from "react-router-dom";
import Pagination from "../../Tools/Pagination";
import { FiFilter } from "react-icons/fi";
import { useContextTranslate } from "../../components/Translate/ContextProvider";
import image from "../../images/Group 4290.png";

const Property = ({
  filters,
  pram,
  types,
  name,
  ex,
  showSlider,
  NewProperty,
}) => {
  const { search } = useLocation();
  const { data, isLoading } = useFETCH(
    `properties${pram ? pram + "&" + search.substring(1) : search}`
  );
  const { content } = useContextTranslate();
  const [showMenu, setShowMenu] = useState(false);
  return (
    <div className="relative">
      <img
        src={image}
        alt=""
        className="absolute top-1/2 -translate-y-1/2 -z-10"
      />
      {showSlider ? "" : <SecondSlider name={name} />}
      <div className="min-h-[45vh]">
        <Container className="max-sm:w-full">
          {filters && (
            <Row className="items-center">
              {ex && (
                <Col col={9}>
                  <h4 className="text-3xl text-Main max-sm:text-2xl font-bold">
                    {content.ExplorePropertiesIn + " " + name}
                  </h4>
                </Col>
              )}
              <Col md={3}>
                <div
                  onClick={() => setShowMenu(true)}
                  className="flex items-center justify-center gap-2 bg-Secondary rounded-xl text-white text-center text-xl font-semibold py-2 px-8"
                >
                  <p>{content.Filter}</p>
                  <FiFilter />
                </div>
              </Col>
              <Filters
                setShow={setShowMenu}
                show={showMenu}
                types={types}
                total={data?.data.data.total}
              />
            </Row>
          )}
          {isLoading ? (
            <Row>
              <LoadingPro />
              <LoadingPro />
              <LoadingPro />
              <LoadingPro />
              <LoadingPro />
              <LoadingPro />
              <LoadingPro />
            </Row>
          ) : (
            <Pagination
              pageCount={Math.ceil(
                data?.data.data.total / data?.data.data.per_page
              )}
            >
              <Row>
                {data?.data.data.data.map((e) => (
                  <Col lg={4} sm={6}>
                    <Product
                      id={e.id}
                      NewProperty={NewProperty}
                      details="asd"
                      title={e.name}
                      address={e.address}
                      room={e.rooms}
                      houseArea={e.house_area}
                      image={fileUrl + e.main_image?.file}
                      bathroom={e.bathroom}
                      whatsapp={e.whatsapp}
                      phone={e.phone_number}
                      price={e.price}
                      isFavorite={e.is_favorite}
                    />
                  </Col>
                ))}
              </Row>
            </Pagination>
          )}
        </Container>
      </div>
    </div>
  );
};

export default Property;
const LoadingPro = () => {
  return (
    <Col lg={4} sm={6}>
      <div className="w-full h-[400px]  rounded-2xl p-5 bg-white border">
        <div className="bg-slate-300 animate-pulse w-full h-[220px] rounded-2xl"></div>
        <div className="bg-slate-300 animate-pulse w-2/3 h-6 my-2 "></div>
        <div className="bg-slate-300 animate-pulse w-2/5 h-5 my-2 "></div>
        <div className="flex gap-5">
          <div className="bg-slate-300 animate-pulse w-8 h-8 my-2 rounded-full"></div>
          <div className="bg-slate-300 animate-pulse w-8 h-8 my-2 rounded-full"></div>
          <div className="bg-slate-300 animate-pulse w-8 h-8 my-2 rounded-full"></div>
        </div>
        <div className="bg-slate-300 animate-pulse w-2/5 h-5 my-2 "></div>
      </div>
    </Col>
  );
};
